export function unsplashCrop(url: string, pixels = 512) {
    if (url.startsWith("https://images.unsplash.com/")) {
        return `${url}&w=${pixels}`;
    } else {
        return url;
    }
}

export function getFlagEmoji(countryCode) {
    const codePoints = countryCode
        .toUpperCase()
        .split("")
        .map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
}

export type Concrete<Type> = {
    [Property in keyof Type]-?: Type[Property];
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function remapEmptyStringsToUndefined(obj) {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
            key,
            value === "" ? undefined : value,
        ]),
    );
}
